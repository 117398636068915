import React, { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { Grid, Container, Header, Image } from 'semantic-ui-react';
import ReactPlayer from 'react-player/lazy';
import FadeInSection from '../components/FadeInSection';
import lily from '../assets/Lily01.jpg'
import car from '../assets/car.png';
import rs from '../assets/rs.png';
import wcr from '../assets/wcr.png';
import sfar from '../assets/sfar.jpeg';
import green from '../assets/green-logo.jpg';
import gold from '../assets/gold.png';
import { Helmet } from 'react-helmet-async';
import '../styles/About.scss';

const bryant = 'https://res.cloudinary.com/dlaituqwq/image/upload/v1738740054/Lily_Bryan-3_b7p8gn.jpg'

function About() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });
  return (
    <>
      <Helmet>
        <meta name='description' content='Get to know the heart behind Lily Lai Real Estate. Our story, values, and passion for creating exceptional real estate experiences. Welcome to the essence of who we are.' />
        <link rel='canonical' href='/about' />
      </Helmet>
      <div className="about">
        <h1 className='title'>About The Team</h1>
        <Container className='about-text'>
          <h2 className='title'>Lily Lai - Principal Agent</h2>
          <div className='video'>
            <ReactPlayer
              url="https://streamable.com/xdkjwt"
              className="react-player"
              playing={false}
              width="100%"
              height="100%"
            />
          </div>
          <Image className='image' title='portrait' alt='portrait' src={lily} size='medium'/>
          <Container>
            <p>
              <FadeInSection>
                I am a native San Franciscan who has traveled to many parts of the world but wouldn't call any place home other than the Bay Area. Real estate merges my love of architecture, interior design, and client service into a business that brings me great joy.
              </FadeInSection>
            </p>
            <p>
              <FadeInSection>
                I cherish my role as a trusted advisor, guiding clients in navigating through the many challenges of the competitive local market when buying their first home. Or presenting a home in the most attractive way, from leveraging intimate knowledge of active buyers' preferences when selling a home driven by job relocation or downsizing and all that's in between.
              </FadeInSection>
            </p>
            <p>
              <FadeInSection>
                My years working alongside my dad delivering small to large-scale renovation projects offer me a unique perspective when assessing the opportunities in modernizing the home to meet the needs of modern-day lifestyle and the construction costs associated with such value add.
              </FadeInSection>
            </p>
            <p>
              <FadeInSection>
                I am committed, dedicated, and enthusiastic when working with clients who are ready to transition into the next stage of life. I am confident I will bring a positive impact to this life-changing process.
              </FadeInSection>
            </p>
            <p>
              <FadeInSection>
                <b>Languages:</b> English, Chinese(中文 - 廣東話/普通話)
              </FadeInSection>
            </p>
          </Container>

          
        </Container>
        <Container className='affiliations'>
          <FadeInSection>
            <h1 className='title'>Affiliations</h1>
          </FadeInSection>
          <FadeInSection>
            <li className='affiliation'>Women's Council of Realtors San Francisco, 2024 President</li>
          </FadeInSection>
          <FadeInSection>
            <li className='affiliation'>Women's Council of Realtors San Francisco, 2023 First Vice President</li>
          </FadeInSection> 
          <FadeInSection>
            <li className='affiliation'>Compass's Rising Star, 2023</li>
          </FadeInSection>
          <FadeInSection>
            <li className='affiliation'>National Association of Realtors, Member </li>
          </FadeInSection>
          <FadeInSection>
            <li className='affiliation'>California Association of Realtors, Member </li>
          </FadeInSection>
          <FadeInSection>
            <li className='affiliation'>San Francisco Association of Realtors, Member </li>
          </FadeInSection>
          <FadeInSection>
            <li className='affiliation'>National Association of Realtor's Green Designation</li>
          </FadeInSection>
          <Grid className='affiliation-logos' centered>
            <Grid.Row centered  verticalAlign='middle'>
              <Grid.Column mobile={8} tablet={4} computer={2} textAlign='justified'>
                <FadeInSection>
                  <Image className='logo' title='car' alt='car' src={car} size='small' centered/>
                </FadeInSection>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={4} computer={2}>
                <FadeInSection>
                  <Image className='logo' title='sfar' alt='sfar' src={sfar} size='small' centered/>
                </FadeInSection>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={4} computer={2}>
                <FadeInSection>
                  <Image className='logo' title='wcr' alt='wcr' src={wcr} size='small' centered/>
                </FadeInSection>
              </Grid.Column>

              <Grid.Column mobile={8} tablet={4} computer={2}>
                <FadeInSection>
                  <Image className='logo' title='green' alt='green' src={green} size='small' centered/>
                </FadeInSection>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={4} computer={2}>
                <FadeInSection>
                  <Image className='logo' title='rs' alt='rs' src={rs} size='small' centered/>
                </FadeInSection>
              </Grid.Column>
              <Grid.Column mobile={8} tablet={4} computer={2}>
                <FadeInSection>
                  <Image className='logo' title='rs' alt='rs' src={gold} size='small' centered/>
                </FadeInSection>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
        <Container className="bryant">
        <FadeInSection>
          <h2 className="title">Bryant Huang – Real Estate Professional & Strategist</h2>
        </FadeInSection>

        <div className="bryant-content about-text">
        <Image className='image' title='portrait' alt='portrait' src={bryant} size='medium'/>
          <p>
            <FadeInSection>
              My journey into real estate is as dynamic as the properties I help my clients discover. Before stepping into the world of real estate, I spent years as a software engineer, crafting seamless user experiences and solving complex problems through code. While I loved the analytical challenges, I realized my true passion lay in something more tangible—helping people find a place they could truly call home.
            </FadeInSection>
          </p>

          <p>
            <FadeInSection>
              In 2017, I took a leap of faith and moved to San Francisco on my own. The city’s energy, diversity, and architectural charm captivated me instantly. I found myself drawn not just to the skyline but to the intricate details of every home, the stories behind each neighborhood, and the potential hidden within every property.
            </FadeInSection>
          </p>

          <p>
            <FadeInSection>
              Real estate is more than just transactions—it’s about understanding market trends, investment potential, and the personal aspirations of every client. My background in technology allows me to bring a>data-driven approach, leveraging market analytics to give my clients a competitive edge. Whether it’s finding the perfect home, optimizing a sale strategy, or evaluating investment opportunities, I bring a unique blend of logic and creativity to every deal.
            </FadeInSection>
          </p>
          <p>
            <FadeInSection>
              Joining forces with my wife in the real estate business has been an incredible journey. Together, we combine experience, innovation, and heartfelt dedication to guide our clients through one of the most significant decisions of their lives. We are committed to making the process seamless, strategic, and rewarding —whether you’re a first-time buyer, a seasoned investor, or looking to sell in this competitive market.
            </FadeInSection>
          </p>

          <p>
            <FadeInSection>
              Let’s Make Your Next Move Smart & Successful. I believe that real estate is not just about buying and selling—it’s about building futures. I look forward to helping you navigate the ever-evolving Bay Area market with confidence and clarity.
            </FadeInSection>
          </p>
        </div>
      </Container>
      </div>
    </>
  );
}

export default About;
